import { translateTitle } from '@/utils/i18n';
export default {
  __name: 'VabSubMenu',
  props: {
    itemOrMenu: {
      type: Object,

      default() {
        return null;
      }

    }
  },

  setup(__props, {
    expose
  }) {
    expose();
    const __returned__ = {
      translateTitle
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};