import request from '@/utils/request'
import { encryptedData } from '@/utils/encrypt'
import { loginRSA } from '@/config'

// export async function login(data) {
//   if (loginRSA) {
//     data = await encryptedData(data)
//   }
//   console.log(data)
//   return request({
//     url: '/api/system/index/login',
//     method: 'post',
//     data,
//   })
// }

export async function login(data) {
  return request({
    url: '/api/system/index/login',
    method: 'post',
    data,
  })
}
// 获取验证码
export async function sms(params) {
  return request({
    url: '/api/system/index/sms',
    method: 'get',
    params,
  })
}
export async function socialLogin(data) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/socialLogin',
    method: 'post',
    data,
  })
}

export function getUserInfo() {
  return request({
    url: '/api/system/common/get_user_info',
    method: 'get',
  })
}

export function logout(params) {
  return request({
    url: '/api/system/index/login_out',
    method: 'get',
    params,
  })
}

export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data,
  })
}
