/* unplugin-vue-components disabled */import { useSettingsStore } from '@/store/modules/settings';
import { translateTitle } from '@/utils/i18n';
import { get_message_list, clear_all_meg } from '@/api/message';
export default {
  __name: 'index',

  setup(__props, {
    expose
  }) {
    expose();
    const $baseMessage = inject('$baseMessage');
    const settingsStore = useSettingsStore();
    const {
      theme
    } = storeToRefs(settingsStore);
    const activeName = ref('notice');
    const notices = ref('');
    const badge = ref(undefined);

    const fetchData = async () => {
      const {
        data: {
          list,
          count
        }
      } = await get_message_list({
        unread: 1
      });
      notices.value = list;
      badge.value = count === 0 ? undefined : count;
    };

    nextTick(() => {
      if (theme.value.showNotice) fetchData();
    });

    const handleClick = () => {
      fetchData();
    };

    const handleClearNotice = () => {
      clear_all_meg().then(res => {
        if (res.code == 200) {
          badge.value = undefined;
          notices.value = [];
          $baseMessage('清空消息成功', 'success', 'vab-hey-message-success');
        } else {
          $baseMessage('清空失败', 'error', 'vab-hey-message-error');
        }
      });
    };

    const __returned__ = {
      $baseMessage,
      settingsStore,
      theme,
      activeName,
      notices,
      badge,
      fetchData,
      handleClick,
      handleClearNotice,
      useSettingsStore,
      translateTitle,
      get_message_list,
      clear_all_meg
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};