/* unplugin-vue-components disabled */import { useRoutesStore } from '@/store/modules/routes';
import { translateTitle } from '@/utils/i18n';
import { handleMatched } from '@/utils/routes';
export default {
  __name: 'index',

  setup(__props, {
    expose
  }) {
    expose();
    const route = useRoute();
    const routesStore = useRoutesStore();
    const {
      getRoutes: routes
    } = storeToRefs(routesStore);
    const breadcrumbList = computed(() => handleMatched(routes.value, route.path).filter(item => !item.meta.breadcrumbHidden));

    const handleTo = (path = '') => {
      return {
        path
      };
    };

    const __returned__ = {
      route,
      routesStore,
      routes,
      breadcrumbList,
      handleTo,
      useRoutesStore,
      translateTitle,
      handleMatched
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};