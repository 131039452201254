/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import Layout from '@vab/layouts'
import { setupPermissions } from './permissions'
import { authentication, isHashRouterMode, publicPath } from '@/config'

export const constantRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('@/views/callback'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
    },
  },
]

export const asyncRoutes = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    id: '1',
    meta: {
      title: '首页',
      icon: 'home-2-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index'),
        meta: {
          title: '首页',
          icon: 'home-2-line',
          noClosable: true,
        },
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/index/dashboard'),
        meta: {
          title: '看板',
          icon: 'dashboard-line',
        },
      },
      {
        path: 'workbench',
        name: 'Workbench',
        component: () => import('@/views/index/workbench'),
        meta: {
          title: '工作台',
          icon: 'settings-6-line',
          dot: true,
        },
      },
    ],
  },

  {
    path: '/customer',
    name: 'Customer',
    component: Layout,
    meta: {
      title: '山海客户',
      icon: 'tools-line',
      levelHidden: true,
      guard: ['Admin'],
    },
    children: [
      {
        path: 'intentionalCustomer',
        name: 'IntentionalCustomer',
        component: () =>
          import('@/views/intentionalCustomer/intentionalCustomer'),
        meta: {
          title: '意向客户',
          icon: 'home-2-line',
        },
      },
      {
        path: 'customerList',
        name: 'customerList',
        component: () => import('@/views/intentionalCustomer/customerList'),
        meta: {
          title: '正式客户',
          icon: 'home-2-line',
        },
      },
      {
        path: 'customerDetail',
        name: 'customerDetail',
        component: () => import('@/views/intentionalCustomer/customerDetail'),
        meta: {
          hidden: true,
          title: '查看电站',
          activeMenu: '/vab/intentionalCustomer/customerList',
          dynamicNewTab: true, //详情页根据id传参不同可打开多个
        },
      },
    ],
  },
  {
    path: '/stationManagement',
    name: 'StationManagement',
    component: Layout,
    meta: {
      title: '电站管理',
      icon: 'tools-line',
      levelHidden: true,
      guard: ['Admin'],
    },
    children: [
      {
        path: 'stationList',
        name: 'tationList',
        component: () => import('@/views/stationManagement/stationList'),
        meta: {
          title: '电站管理',
          icon: 'home-2-line',
        },
      },
      {
        path: 'stationDetail',
        name: 'StationDetail',
        component: () => import('@/views/stationManagement/stationDetail'),
        meta: {
          hidden: true,
          title: '详情页',
          activeMenu: '/vab/stationManagement/stationList',
          dynamicNewTab: true, //详情页根据id传参不同可打开多个
        },
      },
      {
        path: 'pdfs',
        name: 'pdfs',
        component: () => import('@/views/stationManagement/pdfs'),
        meta: {
          hidden: true,
          title: 'pdf页面',
          activeMenu: '/vab/stationManagement/stationList',
          dynamicNewTab: true, //详情页根据id传参不同可打开多个
        },
      },
      {
        path: 'buildWorkOrderList',
        name: 'buildWorkOrderList',
        component: () => import('@/views/stationManagement/buildWorkOrderList'),
        meta: {
          title: '施工派工列表',
          icon: 'home-2-line',
        },
      },
      {
        path: 'buildSelfAuditList',
        name: 'buildSelfAuditList',
        component: () => import('@/views/stationManagement/buildSelfAuditList'),
        meta: {
          title: '施工完工自审列表',
          icon: 'home-2-line',
        },
      },
      {
        path: 'buildSelfAuditDetail',
        name: 'buildSelfAuditDetail',
        component: () =>
          import('@/views/stationManagement/buildSelfAuditDetail'),
        meta: {
          hidden: true,
          title: '详情页',
          activeMenu: '/vab/stationManagement/buildSelfAuditList',
          dynamicNewTab: true, //详情页根据id传参不同可打开多个
        },
      },
      {
        path: 'ongridWorkOrderList',
        name: 'ongridWorkOrderList',
        component: () =>
          import('@/views/stationManagement/ongridWorkOrderList'),
        meta: {
          title: '并网派工列表',
          icon: 'home-2-line',
        },
      },
      {
        path: 'ongridSelfAuditList',
        name: 'ongridSelfAuditList',
        component: () =>
          import('@/views/stationManagement/ongridSelfAuditList'),
        meta: {
          title: '并网完工自审列表',
          icon: 'home-2-line',
        },
      },
      {
        path: 'ongridselfauditdetails',
        name: 'ongridselfauditdetails',
        component: () =>
          import('@/views/stationManagement/ongridselfauditdetails'),
        meta: {
          hidden: true,
          title: '并网自审详情',
          activeMenu: '/vab/stationManagement/ongridSelfAuditList',
          dynamicNewTab: true, //详情页根据id传参不同可打开多个
        },
      },
      {
        path: 'gebMatter',
        name: 'gebMatter',
        component: () => import('@/views/stationManagement/gebMatter'),
        meta: {
          title: '光E宝',
          icon: 'home-2-line',
        },
      },
      {
        path: 'matterDetails',
        name: 'matterDetails',
        component: () => import('@/views/stationManagement/matterDetails'),
        meta: {
          hidden: true,
          title: '光E宝详情',
          activeMenu: '/vab/stationManagement/gebMatter',
          dynamicNewTab: true, //详情页根据id传参不同可打开多个
        },
      },
      {
        path: 'inputagent',
        name: 'inputagent',
        component: () => import('@/views/stationManagement/inputagent'),
        meta: {
          title: '代理商管理',
          icon: 'home-2-line',
        },
      },
      {
        path: 'inputagentDetail',
        name: 'inputagentDetail',
        component: () => import('@/views/stationManagement/inputagentDetail'),
        meta: {
          hidden: true,
          title: '资料录入',
          activeMenu: '/vab/stationManagement/inputagent',
          dynamicNewTab: true, //详情页根据id传参不同可打开多个
        },
      },
    ],
  },
  {
    path: '/other',
    name: 'Other',
    component: Layout,
    meta: {
      title: '其他',
      icon: 'archive-line',
      guard: ['Admin'],
    },
    children: [
      {
        path: 'workflow',
        name: 'Workflow',
        component: () => import('@/views/other/workflow'),
        meta: {
          title: '工作流',
          guard: ['Admin'],
          icon: 'flow-chart',
        },
      },
    ],
  },
  {
    path: '/setting',
    name: 'PersonnelManagement',
    component: Layout,
    meta: {
      title: '配置',
      icon: 'user-settings-line',
      guard: ['Admin'],
    },
    children: [
      {
        path: 'personalCenter',
        name: 'PersonalCenter',
        component: () => import('@/views/setting/personalCenter'),
        meta: {
          title: '个人中心',
          icon: 'map-pin-user-line',
        },
      },
      {
        path: 'userManagement',
        name: 'UserManagement',
        component: () => import('@/views/setting/userManagement'),
        meta: {
          title: '用户管理',
          icon: 'user-3-line',
        },
      },
      {
        path: 'roleManagement',
        name: 'RoleManagement',
        component: () => import('@/views/setting/roleManagement'),
        meta: {
          title: '角色管理',
          icon: 'admin-line',
        },
      },
      {
        path: 'departmentManagement',
        name: 'DepartmentManagement',
        component: () => import('@/views/setting/departmentManagement'),
        meta: {
          title: '客户管理',
          icon: 'group-line',
        },
      },
      {
        path: 'menuManagement',
        name: 'MenuManagement',
        component: () => import('@/views/setting/menuManagement'),
        meta: {
          title: '菜单管理',
          icon: 'menu-2-fill',
        },
      },
      {
        path: 'taskManagement',
        name: 'TaskManagement',
        component: () => import('@/views/setting/taskManagement'),
        meta: {
          title: '任务管理',
          icon: 'task-line',
          badge: 'New',
        },
      },
      {
        path: 'systemLog',
        name: 'SystemLog',
        component: () => import('@/views/setting/systemLog'),
        meta: {
          title: '系统日志',
          icon: 'file-shield-2-line',
        },
      },
    ],
  },
  // {
  //   path: '/error',
  //   name: 'Error',
  //   component: Layout,
  //   meta: {
  //     title: '错误页',
  //     icon: 'error-warning-line',
  //     levelHidden: true,
  //   },
  //   children: [
  //     {
  //       path: '403',
  //       name: 'Error403',
  //       component: () => import('@/views/403'),
  //       meta: {
  //         title: '403',
  //         icon: 'error-warning-line',
  //       },
  //     },
  //     {
  //       path: '404',
  //       name: 'Error404',
  //       component: () => import('@/views/404'),
  //       meta: {
  //         title: '404',
  //         icon: 'error-warning-line',
  //       },
  //     },
  //   ],
  // },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    name: 'NotFound',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter({
  history: isHashRouterMode
    ? createWebHashHistory(publicPath)
    : createWebHistory(publicPath),
  routes: constantRoutes,
})

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

function addRouter(routes) {
  routes.forEach((route) => {
    if (!router.hasRoute(route.name)) router.addRoute(route)
    if (route.children) addRouter(route.children)
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.getRoutes().forEach((route) => {
    const routeName = route.name
    router.hasRoute(routeName) && router.removeRoute(routeName)
  })
  addRouter(routes)
}

export function setupRouter(app) {
  if (authentication === 'intelligence') addRouter(asyncRoutes)
  setupPermissions(router)
  app.use(router)
  return router
}

export default router
