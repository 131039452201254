/* unplugin-vue-components disabled */import { useSettingsStore } from '@/store/modules/settings';
export default {
  __name: 'index',

  setup(__props, {
    expose
  }) {
    expose();
    const fullYear = new Date().getFullYear();
    const settingsStore = useSettingsStore();
    const {
      title
    } = storeToRefs(settingsStore);
    const __returned__ = {
      fullYear,
      settingsStore,
      title,
      useSettingsStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};