import "core-js/modules/es.array.push.js";

/**
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import { asyncRoutes, constantRoutes, resetRouter } from '@/router';
import { convertRouter, filterRoutes } from '@/utils/routes';
import { authentication, rolesControl } from '@/config';
import { isArray } from '@/utils/validate';
import { getList } from '@/api/router';
import { gp } from '@gp';
import { useUserStore } from '@/store/modules/user';
export const useRoutesStore = defineStore('routes', {
  state: () => ({
    /**
     * 一级菜单值
     */
    tab: {
      data: undefined
    },

    /**
     * 一级菜单
     */
    tabMenu: undefined,

    /**
     * 自定义激活菜单
     */
    activeMenu: {
      data: undefined
    },

    /**
     * 一级菜单
     */
    routes: []
  }),
  getters: {
    getTab: state => state.tab,
    getTabMenu: state => state.tab.data ? state.routes.find(route => route.name === state.tab.data) : {
      meta: {
        title: ''
      }
    },
    getActiveMenu: state => state.activeMenu,
    getRoutes: state => state.routes.filter(_route => _route.meta && _route.meta.hidden !== true),
    getPartialRoutes: state => state.tab.data ? state.routes.find(route => route.name === state.tab.data) && state.routes.find(route => route.name === state.tab.data).children : []
  },
  actions: {
    clearRoutes() {
      this.routes = [];
    },

    /**
     * @description 多模式设置路由
     * @param mode
     * @returns
     */
    async setRoutes(mode = 'none') {
      // 默认前端路由
      let routes = [...asyncRoutes]; // 设置游客路由关闭路由拦截(不需要可以删除)

      const control = mode === 'visit' ? false : rolesControl; // 设置后端路由(不需要可以删除)

      if (authentication === 'all') {
        const {
          id
        } = useUserStore();
        const {
          data
        } = await getList({
          hide: 1,
          admin_id: id,
          all: true,
          filter: true,
          filter_checkout: true
        });
        if (!isArray(data)) gp.$baseMessage('路由格式返回有误！', 'error', 'vab-hey-message-error');
        if (data[data.length - 1].path !== '*') data.push({
          path: '/:pathMatch(.*)*',
          redirect: '/404',
          name: 'NotFound',
          meta: {
            hidden: true
          }
        });
        data.map(item => {
          if (item.meta.dot == 1) {
            item.meta.dot = true;
          } else {
            item.meta.dot = false;
          }

          if (item.meta.hidden == 1) {
            item.meta.hidden = true;
          } else {
            item.meta.hidden = false;
          }

          if (item.meta.levelHidden == 1) {
            item.meta.levelHidden = true;
          } else {
            item.meta.levelHidden = false;
          }

          if (item.meta.noKeepAlive == 1) {
            item.meta.noKeepAlive = true;
          } else {
            item.meta.noKeepAlive = false;
          }

          if (item.meta.dynamicNewTab == 1) {
            item.meta.dynamicNewTab = true;
          } else {
            item.meta.dynamicNewTab = false;
          }

          if (item.meta.breadcrumbHidden == 1) {
            item.meta.breadcrumbHidden = true;
          } else {
            item.meta.breadcrumbHidden = false;
          }

          if (item.meta.noClosable == 1) {
            item.meta.noClosable = true;
          } else {
            item.meta.noClosable = false;
          }

          if (item.children) {
            item.children.map(items => {
              if (items.meta.dot == 1) {
                items.meta.dot = true;
              } else {
                items.meta.dot = false;
              }

              if (items.meta.hidden == 1) {
                items.meta.hidden = true;
              } else {
                items.meta.hidden = false;
              }

              if (items.meta.levelHidden == 1) {
                items.meta.levelHidden = true;
              } else {
                items.meta.levelHidden = false;
              }

              if (items.meta.noKeepAlive == 1) {
                items.meta.noKeepAlive = true;
              } else {
                items.meta.noKeepAlive = false;
              }

              if (items.meta.dynamicNewTab == 1) {
                items.meta.dynamicNewTab = true;
              } else {
                items.meta.dynamicNewTab = false;
              }

              if (items.meta.breadcrumbHidden == 1) {
                items.meta.breadcrumbHidden = true;
              } else {
                items.meta.breadcrumbHidden = false;
              }

              if (items.meta.noClosable == 1) {
                items.meta.noClosable = true;
              } else {
                items.meta.noClosable = false;
              }
            });
          }
        });
        routes = convertRouter(data);
      } // 根据权限和rolesControl过滤路由


      const accessRoutes = filterRoutes([...constantRoutes, ...routes], control); // 设置菜单所需路由

      this.routes = JSON.parse(JSON.stringify(accessRoutes)); // 根据可访问路由重置Vue Router

      await resetRouter(accessRoutes);
    },

    changeMenuMeta(options) {
      function handleRoutes(routes) {
        return routes.map(route => {
          if (route.name === options.name) Object.assign(route.meta, options.meta);
          if (route.children && route.children.length) route.children = handleRoutes(route.children);
          return route;
        });
      }

      this.routes = handleRoutes(this.routes);
    },

    /**
     * @description 修改 activeName
     * @param activeMenu 当前激活菜单
     */
    changeActiveMenu(activeMenu) {
      this.activeMenu.data = activeMenu;
    }

  }
});