/* unplugin-vue-components disabled */import { useSettingsStore } from '@/store/modules/settings';
export default {
  __name: 'index',
  props: {
    collapse: {
      type: Boolean,

      default() {
        return false;
      }

    },
    fixedHeader: {
      type: Boolean,

      default() {
        return true;
      }

    },
    showTabs: {
      type: Boolean,

      default() {
        return true;
      }

    }
  },

  setup(__props, {
    expose
  }) {
    expose();
    const settingsStore = useSettingsStore();
    const {
      theme
    } = storeToRefs(settingsStore);
    const __returned__ = {
      settingsStore,
      theme,
      useSettingsStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};