/* unplugin-vue-components disabled */import "core-js/modules/es.array.push.js";
import { useSettingsStore } from '@/store/modules/settings';
import { isExternal } from '@/utils/validate';
import { translateTitle } from '@/utils/i18n';
import { isHashRouterMode } from '@/config';
export default {
  __name: 'VabMenuItem',
  props: {
    itemOrMenu: {
      type: Object,

      default() {
        return null;
      }

    }
  },

  setup(__props, {
    expose
  }) {
    expose();
    const props = __props;
    const route = useRoute();
    const router = useRouter();
    const $pub = inject('$pub');
    const settingsStore = useSettingsStore();
    const {
      device
    } = storeToRefs(settingsStore);
    const {
      foldSideBar
    } = settingsStore;

    const handleLink = () => {
      const routePath = props.itemOrMenu.path;
      const target = props.itemOrMenu.meta.target;

      if (target === '_blank') {
        if (isExternal(routePath)) window.open(routePath);else if (route.path !== routePath) isHashRouterMode ? window.open('/#' + routePath) : window.open(routePath);
      } else {
        if (isExternal(routePath)) window.location.href = routePath;else if (route.path !== routePath) {
          if (device.value === 'mobile') foldSideBar();
          router.push(props.itemOrMenu.path);
        } else $pub('reload-router-view');
      }
    };

    const __returned__ = {
      props,
      route,
      router,
      $pub,
      settingsStore,
      device,
      foldSideBar,
      handleLink,
      useSettingsStore,
      isExternal,
      translateTitle,
      isHashRouterMode
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};