import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue";
const _hoisted_1 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_menu = _resolveComponent("vab-menu");

  const _component_vab_icon = _resolveComponent("vab-icon");

  const _component_el_sub_menu = _resolveComponent("el-sub-menu");

  return $props.itemOrMenu.meta && $props.itemOrMenu.meta.levelHidden ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($props.itemOrMenu.children, route => {
    return _openBlock(), _createBlock(_component_vab_menu, {
      key: route.path,
      item: route
    }, null, 8
    /* PROPS */
    , ["item"]);
  }), 128
  /* KEYED_FRAGMENT */
  )) : (_openBlock(), _createBlock(_component_el_sub_menu, {
    key: 1,
    index: $props.itemOrMenu.path,
    "popper-append-to-body": ""
  }, {
    title: _withCtx(() => [$props.itemOrMenu.meta && $props.itemOrMenu.meta.icon ? (_openBlock(), _createBlock(_component_vab_icon, {
      key: 0,
      icon: $props.itemOrMenu.meta.icon,
      "is-custom-svg": $props.itemOrMenu.meta.isCustomSvg,
      title: $setup.translateTitle($props.itemOrMenu.meta.title)
    }, null, 8
    /* PROPS */
    , ["icon", "is-custom-svg", "title"])) : _createCommentVNode("v-if", true), _createElementVNode("span", {
      title: $setup.translateTitle($props.itemOrMenu.meta.title)
    }, _toDisplayString($setup.translateTitle($props.itemOrMenu.meta.title)), 9
    /* TEXT, PROPS */
    , _hoisted_1)]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
    /* FORWARDED */

  }, 8
  /* PROPS */
  , ["index"]));
}