import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "vab-app-main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_VabRouterView = _resolveComponent("VabRouterView");

  const _component_vab_footer = _resolveComponent("vab-footer");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("section", null, [_createVNode(_component_VabRouterView)]), _createVNode(_component_vab_footer)]);
}