/* unplugin-vue-components disabled */import { useErrorLogStore } from '@/store/modules/errorLog';
export default {
  __name: 'index',

  setup(__props, {
    expose
  }) {
    expose();
    const errorLogStore = useErrorLogStore();
    const {
      errorLogs
    } = storeToRefs(errorLogStore);
    const {
      clearErrorLog
    } = errorLogStore;
    const state = reactive({
      dialogVisible: false
    });
    const searchList = [{
      title: '百度搜索',
      url: 'https://www.baidu.com/baidu?wd=',
      icon: 'baidu-line'
    }, {
      title: '谷歌搜索',
      url: 'https://www.google.com/search?q=',
      icon: 'google-line'
    }];

    const clearAll = () => {
      state.dialogVisible = false;
      clearErrorLog();
    };

    const __returned__ = {
      errorLogStore,
      errorLogs,
      clearErrorLog,
      state,
      searchList,
      clearAll,
      useErrorLogStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};