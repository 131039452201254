/* unplugin-vue-components disabled */import i18n from '@/i18n';
import { pwa } from '@/config';
export default {
  __name: 'index',

  setup(__props, {
    expose
  }) {
    expose();
    const VabUpdate = defineAsyncComponent(() => import('@/plugins/VabUpdate'));
    const locale = toRef(i18n.global, 'locale');
    const messages = toRef(i18n.global, 'messages'); // onMounted(() => {
    //   window.addEventListener('scroll', onScroll,true)
    //   })
    //   const onScroll=()=> {
    //     console.log('sbdjfsbjdbsshwueyuwjnbvuwgu')
    //         var scrolled =
    //          document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
    //           console.log(scrolled)
    //           console.log(document.querySelector(".tables").scrollTop)
    //       }

    const __returned__ = {
      VabUpdate,
      locale,
      messages,
      i18n,
      pwa
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};