/* unplugin-vue-components disabled */import "core-js/modules/es.error.cause.js";
import { useUserStore } from '@/store/modules/user';
import { useSettingsStore } from '@/store/modules/settings';
export default {
  __name: 'index',

  setup(__props, {
    expose
  }) {
    expose();
    const vFocus = {
      mounted(el) {
        el.querySelector('input').focus();
      }

    };
    const userStore = useUserStore();
    const {
      avatar
    } = storeToRefs(userStore);
    const settingsStore = useSettingsStore();
    const {
      theme,
      lock,
      title
    } = storeToRefs(settingsStore);
    const {
      handleLock: _handleLock,
      handleUnLock: _handleUnLock
    } = settingsStore;
    const background = ref(`https://gitee.com/chu1204505056/image/raw/master/vab-image-lock/${Math.round(Math.random() * 31)}.jpg`);

    const randomBackground = () => {
      background.value = `https://gitee.com/chu1204505056/image/raw/master/vab-image-lock/${Math.round(Math.random() * 31)}.jpg`;
    };

    const validatePass = (rule, value, callback) => {
      if (value === '' || value !== '123456') {
        callback(new Error('请输入正确的密码'));
      } else {
        callback();
      }
    };

    const formRef = ref();
    const form = ref({
      password: '123456'
    });
    const rules = {
      password: [{
        validator: validatePass,
        trigger: 'blur'
      }]
    };
    let lockIcon = true;

    const handleUnLock = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          lockIcon = false;
          setTimeout(async () => {
            await _handleUnLock();
            lockIcon = true;
            await randomBackground();
            if (document.getElementsByClassName('vab-side-bar')[0]) document.getElementsByClassName('vab-side-bar')[0].style.display = '';
          }, 500);
        }
      });
    };

    const handleLock = () => {
      _handleLock();

      if (document.getElementsByClassName('vab-side-bar')[0]) document.getElementsByClassName('vab-side-bar')[0].style.display = 'none';
    };

    const __returned__ = {
      vFocus,
      userStore,
      avatar,
      settingsStore,
      theme,
      lock,
      title,
      _handleLock,
      _handleUnLock,
      background,
      randomBackground,
      validatePass,
      formRef,
      form,
      rules,
      lockIcon,
      handleUnLock,
      handleLock,
      useUserStore,
      useSettingsStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};