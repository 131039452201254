import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  const _component_el_config_provider = _resolveComponent("el-config-provider");

  return _openBlock(), _createBlock(_component_el_config_provider, {
    button: {
      autoInsertSpace: true
    },
    locale: $setup.messages[$setup.locale]
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view), $setup.pwa ? (_openBlock(), _createBlock($setup["VabUpdate"], {
      key: 0,
      ref: "vabUpdateRef"
    }, null, 512
    /* NEED_PATCH */
    )) : _createCommentVNode("v-if", true)]),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["locale"]);
}