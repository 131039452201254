/* unplugin-vue-components disabled */import { useRoutesStore } from '@/store/modules/routes';
import variables from '@vab/styles/variables/variables.module.scss';
export default {
  __name: 'index',
  props: {
    layout: {
      type: String,
      default: 'horizontal'
    }
  },

  setup(__props, {
    expose
  }) {
    expose();
    const routesStore = useRoutesStore();
    const {
      getActiveMenu: activeMenu,
      getRoutes: routes
    } = storeToRefs(routesStore);
    const __returned__ = {
      routesStore,
      activeMenu,
      routes,
      useRoutesStore,
      variables
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};