import { storage, tokenTableName, idName, identityName,openName,superName,tokerName,roleName } from '@/config'
import cookie from 'js-cookie'

/**
 * @description 获取token
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getToken() {
  console.log(tokenTableName)
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(tokenTableName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(tokenTableName)
    } else if ('cookie' === storage) {
      return cookie.get(tokenTableName)
    } else {
      return localStorage.getItem(tokenTableName)
    }
  } else {
    return localStorage.getItem(tokenTableName)
  }
}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setToken(token) {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(tokenTableName, token)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(tokenTableName, token)
    } else if ('cookie' === storage) {
      return cookie.set(tokenTableName, token)
    } else {
      return localStorage.setItem(tokenTableName, token)
    }
  } else {
    return localStorage.setItem(tokenTableName, token)
  }
}

/**
 * @description 获取id
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getID() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(idName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(idName)
    } else if ('cookie' === storage) {
      return cookie.get(idName)
    } else {
      return localStorage.getItem(idName)
    }
  } else {
    return localStorage.getItem(idName)
  }
}

/**
 * @description 存储ID
 * @param token
 * @returns {void|*}
 */
export function setID(id) {
  if (storage) {
    console.log(id)
    if ('localStorage' === storage) {
      return localStorage.setItem(idName, id)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(idName, id)
    } else if ('cookie' === storage) {
      return cookie.set(idName, id)
    } else {
      return localStorage.setItem(idName, id)
    }
  } else {
    return localStorage.setItem(idName, id)
  }
}
/**
 * @description 获取role
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getRole() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(roleName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(roleName)
    } else if ('cookie' === storage) {
      return cookie.get(roleName)
    } else {
      return localStorage.getItem(roleName)
    }
  } else {
    return localStorage.getItem(roleName)
  }
}

/**
 * @description 存储ID
 * @param token
 * @returns {void|*}
 */
export function setRole(role) {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(roleName, role)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(roleName, role)
    } else if ('cookie' === storage) {
      return cookie.set(roleName, role)
    } else {
      return localStorage.setItem(roleName, role)
    }
  } else {
    return localStorage.setItem(roleName, role)
  }
}
/**
 * @description 获取identity
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getIdentity() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(identityName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(identityName)
    } else if ('cookie' === storage) {
      return cookie.get(identityName)
    } else {
      return localStorage.getItem(identityName)
    }
  } else {
    return localStorage.getItem(identityName)
  }
}

/**
 * @description 存储identity
 * @param identity
 * @returns {void|*}
 */
export function setIdentity(identity) {
  console.log(identity)

  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(identityName, identity)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(identityName, identity)
    } else if ('cookie' === storage) {
      return cookie.set(identityName, identity)
    } else {
      return localStorage.setItem(identityName, identity)
    }
  } else {
    return localStorage.setItem(identityName, identity)
  }
}

/**
 * @description 存储toker
 * @param toker
 * @returns {void|*}
 */
export function setToker(toker) {
  console.log(toker)
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(tokerName, toker)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(tokerName, toker)
    } else if ('cookie' === storage) {
      return cookie.set(tokerName, toker)
    } else {
      return localStorage.setItem(tokerName, toker)
    }
  } else {
    return localStorage.setItem(tokerName, toker)
  }
}
/**
 * @description 获取toker
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getToker() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(tokerName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(tokerName)
    } else if ('cookie' === storage) {
      return cookie.get(tokerName)
    } else {
      return localStorage.getItem(tokerName)
    }
  } else {
    return localStorage.getItem(tokerName)
  }
}
/**
 * @description 获取open
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getOpen() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(openName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(openName)
    } else if ('cookie' === storage) {
      return cookie.get(openName)
    } else {
      return localStorage.getItem(openName)
    }
  } else {
    return localStorage.getItem(openName)
  }
}

/**
 * @description 存储open
 * @param open
 * @returns {void|*}
 */
export function setOpen(open) {

  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(openName, open)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(openName, open)
    } else if ('cookie' === storage) {
      return cookie.set(openName, open)
    } else {
      return localStorage.setItem(openName, open)
    }
  } else {
    return localStorage.setItem(openName, open)
  }
}
/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeToken() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.removeItem(tokenTableName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.clear()
    } else if ('cookie' === storage) {
      return cookie.remove(tokenTableName)
    } else {
      return localStorage.removeItem(tokenTableName)
    }
  } else {
    return localStorage.removeItem(tokenTableName)
  }
}
/**
 * @description 获取super
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getSuper() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(superName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(superName)
    } else if ('cookie' === storage) {
      return cookie.get(superName)
    } else {
      return localStorage.getItem(superName)
    }
  } else {
    return localStorage.getItem(superName)
  }
}

/**
 * @description 存储super
 * @param superr
 * @returns {void|*}
 */
export function setSuper(supers) {

  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(superName, supers)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(superName, supers)
    } else if ('cookie' === storage) {
      return cookie.set(superName, supers)
    } else {
      return localStorage.setItem(superName, supers)
    }
  } else {
    return localStorage.setItem(superName, supers)
  }
}
