import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-d1bcb560"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "vab-main"
};
const _hoisted_2 = {
  class: "vab-main main-padding"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_header = _resolveComponent("vab-header");

  const _component_vab_tabs = _resolveComponent("vab-tabs");

  const _component_vab_app_main = _resolveComponent("vab-app-main");

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vab-layout-horizontal", {
      fixed: $props.fixedHeader,
      'no-tabs-bar': !$props.showTabs
    }])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["vab-layout-header", {
      'fixed-header': $props.fixedHeader
    }])
  }, [_createVNode(_component_vab_header, {
    layout: "horizontal"
  }), _withDirectives(_createElementVNode("div", {
    class: _normalizeClass({
      'vab-tabs-horizontal': $props.showTabs
    })
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_vab_tabs)])], 2
  /* CLASS */
  ), [[_vShow, $props.showTabs]])], 2
  /* CLASS */
  ), _createElementVNode("div", _hoisted_2, [_createVNode(_component_vab_app_main)])], 2
  /* CLASS */
  );
}