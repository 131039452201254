import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_menu = _resolveComponent("vab-menu");

  const _component_el_scrollbar = _resolveComponent("el-scrollbar");

  return _ctx.item.meta && !_ctx.item.meta.hidden ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.menuComponent), {
    key: 0,
    "item-or-menu": _ctx.item
  }, {
    default: _withCtx(() => [_ctx.item.children && _ctx.item.children.length ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_ctx.layout === 'horizontal' && _ctx.item.children.length > 18 || _ctx.layout !== 'horizontal' && _ctx.collapse && _ctx.item.children.length > 18 ? (_openBlock(), _createBlock(_component_el_scrollbar, {
      key: 0,
      class: "vab-menu-children-height"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, route => {
        return _openBlock(), _createBlock(_component_vab_menu, {
          key: route.path,
          item: route
        }, null, 8
        /* PROPS */
        , ["item"]);
      }), 128
      /* KEYED_FRAGMENT */
      ))]),
      _: 1
      /* STABLE */

    })) : (_openBlock(true), _createElementBlock(_Fragment, {
      key: 1
    }, _renderList(_ctx.item.children, route => {
      return _openBlock(), _createBlock(_component_vab_menu, {
        key: route.path,
        item: route
      }, null, 8
      /* PROPS */
      , ["item"]);
    }), 128
    /* KEYED_FRAGMENT */
    ))], 64
    /* STABLE_FRAGMENT */
    )) : _createCommentVNode("v-if", true)]),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["item-or-menu"])) : _createCommentVNode("v-if", true);
}