/* unplugin-vue-components disabled */import "core-js/modules/es.array.push.js";
import { useRoutesStore } from '@/store/modules/routes';
import { translateTitle } from '@/utils/i18n';
import { isExternal } from '@/utils/validate';
import { useUserStore } from '@/store/modules/user';
import { ex_login, su_login } from '@/api/index';
export default {
  __name: 'index',
  props: {
    layout: {
      type: String,
      default: ''
    }
  },

  setup(__props, {
    expose
  }) {
    expose();
    const router = useRouter();
    const {
      identity,
      id,
      open
    } = useUserStore();
    let ident = ref(identity);
    let opens = ref(open);
    const routesStore = useRoutesStore();
    const {
      getTab: tab,
      getTabMenu: tabMenu,
      getRoutes: routes
    } = storeToRefs(routesStore);

    const handleTabClick = () => {
      if (isExternal(tabMenu.value.path)) {
        window.open(tabMenu.value.path);
        setTimeout(() => {
          router.push('/');
        }, 1000);
      } else if (tabMenu.value) {
        const {
          redirect
        } = tabMenu.value;
        router.push(redirect ? redirect : tabMenu.value);
      }
    };

    const yunwei = () => {
      ex_login({
        id: id
      }).then(res => {
        if (res.code == 200) {
          su_login({
            id: res.data.ex,
            identity: identity
          }).then(res => {
            if (process.env.NODE_ENV !== 'development') {
              window.open("https://operation.shanhainengyuan.com/#/index?token=" + res.data.ex.data.token, '_blank');
            } else {
              window.open("http://a1.lx-obj.top/#/index?token=" + res.data.ex.data.token, '_blank');
            }
          });
        }
      });
    };

    const __returned__ = {
      router,
      identity,
      id,
      open,
      ident,
      opens,
      routesStore,
      tab,
      tabMenu,
      routes,
      handleTabClick,
      yunwei,
      useRoutesStore,
      translateTitle,
      isExternal,
      useUserStore,
      ex_login,
      su_login
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};