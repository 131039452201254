import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_side_bar = _resolveComponent("vab-side-bar");

  const _component_vab_nav = _resolveComponent("vab-nav");

  const _component_vab_tabs = _resolveComponent("vab-tabs");

  const _component_vab_app_main = _resolveComponent("vab-app-main");

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vab-layout-vertical", {
      fixed: $props.fixedHeader,
      'no-tabs-bar': !$props.showTabs
    }])
  }, [_createVNode(_component_vab_side_bar), $props.device === 'mobile' && !$props.collapse ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "v-modal",
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.foldSideBar && $setup.foldSideBar(...args))
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(["vab-main", {
      'is-collapse-main': $props.collapse
    }])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["vab-layout-header", {
      'fixed-header': $props.fixedHeader
    }])
  }, [_createVNode(_component_vab_nav), _withDirectives(_createVNode(_component_vab_tabs, null, null, 512
  /* NEED_PATCH */
  ), [[_vShow, $props.showTabs]])], 2
  /* CLASS */
  ), _createVNode(_component_vab_app_main)], 2
  /* CLASS */
  )], 2
  /* CLASS */
  );
}