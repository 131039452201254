/* unplugin-vue-components disabled */import { useSettingsStore } from '@/store/modules/settings';
import getPageTitle from '@/utils/pageTitle';
import i18n from '@/i18n';
export default {
  __name: 'index',

  setup(__props, {
    expose
  }) {
    expose();
    const route = useRoute();
    const settingsStore = useSettingsStore();
    const {
      theme
    } = storeToRefs(settingsStore);
    const {
      changeLanguage
    } = settingsStore;

    const handleCommand = language => {
      changeLanguage(language);
      i18n.global.locale = language;
      document.title = getPageTitle(route.meta.title);
    };

    const __returned__ = {
      route,
      settingsStore,
      theme,
      changeLanguage,
      handleCommand,
      useSettingsStore,
      getPageTitle,
      i18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};