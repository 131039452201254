import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_icon = _resolveComponent("vab-icon");

  const _component_el_tooltip = _resolveComponent("el-tooltip");

  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  const _component_el_form_item = _resolveComponent("el-form-item");

  const _component_el_radio_button = _resolveComponent("el-radio-button");

  const _component_el_radio_group = _resolveComponent("el-radio-group");

  const _component_el_switch = _resolveComponent("el-switch");

  const _component_el_form = _resolveComponent("el-form");

  const _component_el_scrollbar = _resolveComponent("el-scrollbar");

  const _component_el_button = _resolveComponent("el-button");

  const _component_el_drawer = _resolveComponent("el-drawer");

  return _openBlock(), _createBlock(_component_el_drawer, {
    modelValue: $setup.state.drawerVisible,
    "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => $setup.state.drawerVisible = $event),
    "append-to-body": "",
    class: "vab-drawer",
    direction: "rtl",
    size: "285px",
    title: $setup.translateTitle('主题配置')
  }, {
    footer: _withCtx(() => [_createVNode(_component_el_button, {
      type: "primary",
      onClick: $setup.handleSaveTheme
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($setup.translateTitle('保存')), 1
      /* TEXT */
      )]),
      _: 1
      /* STABLE */

    }), _createVNode(_component_el_button, {
      onClick: $setup.setDefaultTheme
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($setup.translateTitle('恢复默认')), 1
      /* TEXT */
      )]),
      _: 1
      /* STABLE */

    })]),
    default: _withCtx(() => [_createVNode(_component_el_scrollbar, {
      height: "88vh"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form, {
        ref: "form",
        "label-position": "left",
        model: $setup.theme
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
          label: _withCtx(() => [_createTextVNode(_toDisplayString($setup.translateTitle('布局')) + " ", 1
          /* TEXT */
          ), _createVNode(_component_el_tooltip, {
            content: $setup.translateTitle('布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局'),
            effect: "dark",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_vab_icon, {
              icon: "question-line"
            })]),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["content"])]),
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: $setup.theme.layout,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.theme.layout = $event),
            disabled: $setup.device === 'mobile',
            placeholder: "请选择"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_option, {
              key: "column",
              label: $setup.translateTitle('分栏'),
              value: "column"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "comprehensive",
              label: $setup.translateTitle('综合'),
              value: "comprehensive"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "vertical",
              label: $setup.translateTitle('纵向'),
              value: "vertical"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "horizontal",
              label: $setup.translateTitle('横向'),
              value: "horizontal"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "common",
              label: $setup.translateTitle('常规'),
              value: "common"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "float",
              label: $setup.translateTitle('浮动'),
              value: "float"
            }, null, 8
            /* PROPS */
            , ["label"])]),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["modelValue", "disabled"])]),
          _: 1
          /* STABLE */

        }), _createVNode(_component_el_form_item, {
          label: $setup.translateTitle('主题')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: $setup.theme.themeName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.theme.themeName = $event),
            onChange: $setup.updateTheme
          }, {
            default: _withCtx(() => [_createVNode(_component_el_option, {
              key: "blue-black",
              label: $setup.translateTitle('蓝黑'),
              value: "blue-black"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "blue-white",
              label: $setup.translateTitle('蓝白'),
              value: "blue-white"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "green-black",
              label: $setup.translateTitle('绿黑'),
              value: "green-black"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "green-white",
              label: $setup.translateTitle('绿白'),
              value: "green-white"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "purple-black",
              label: $setup.translateTitle('紫黑'),
              value: "purple-black"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "purple-white",
              label: $setup.translateTitle('紫白'),
              value: "purple-white"
            }, null, 8
            /* PROPS */
            , ["label"]), _createCommentVNode(" 红黑、红白主题完成群文档任务免费获取 "), _createVNode(_component_el_option, {
              key: "red-black",
              label: $setup.translateTitle('红黑'),
              value: "red-black"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "red-white",
              label: $setup.translateTitle('红白'),
              value: "red-white"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "ocean",
              label: $setup.translateTitle('渐变'),
              value: "ocean"
            }, null, 8
            /* PROPS */
            , ["label"])]),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["modelValue", "onChange"])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"]), _createVNode(_component_el_form_item, {
          class: "vab-item-custom"
        }, {
          label: _withCtx(() => [_createTextVNode(_toDisplayString($setup.translateTitle('菜单背景')) + " ", 1
          /* TEXT */
          ), _createVNode(_component_el_tooltip, {
            content: $setup.translateTitle('支持纵向布局、分栏布局、综合布局、常规布局，不支持横向布局、浮动布局'),
            effect: "dark",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_vab_icon, {
              icon: "question-line"
            })]),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["content"])]),
          default: _withCtx(() => [_createVNode(_component_el_radio_group, {
            modelValue: $setup.theme.background,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.theme.background = $event),
            disabled: $setup.theme.layout === 'float' || $setup.theme.layout === 'horizontal',
            onChange: $setup.updateTheme
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio_button, {
              class: "none",
              label: "none"
            }), _createVNode(_component_el_radio_button, {
              class: "vab-background",
              label: "image"
            })]),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["modelValue", "disabled", "onChange"])]),
          _: 1
          /* STABLE */

        }), _createVNode(_component_el_form_item, {
          label: $setup.translateTitle('标签')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: $setup.theme.showTabs,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $setup.theme.showTabs = $event)
          }, null, 8
          /* PROPS */
          , ["modelValue"])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"]), _createVNode(_component_el_form_item, null, {
          label: _withCtx(() => [_createTextVNode(_toDisplayString($setup.translateTitle('标签图标')) + " ", 1
          /* TEXT */
          ), _createVNode(_component_el_tooltip, {
            content: $setup.translateTitle('标签开启时生效'),
            effect: "dark",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_vab_icon, {
              icon: "question-line"
            })]),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["content"])]),
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: $setup.theme.showTabsIcon,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $setup.theme.showTabsIcon = $event),
            disabled: !$setup.theme.showTabs
          }, null, 8
          /* PROPS */
          , ["modelValue", "disabled"])]),
          _: 1
          /* STABLE */

        }), _createVNode(_component_el_form_item, null, {
          label: _withCtx(() => [_createTextVNode(_toDisplayString($setup.translateTitle('标签风格')) + " ", 1
          /* TEXT */
          ), _createVNode(_component_el_tooltip, {
            content: $setup.translateTitle('标签开启时生效'),
            effect: "dark",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_vab_icon, {
              icon: "question-line"
            })]),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["content"])]),
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: $setup.theme.tabsBarStyle,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $setup.theme.tabsBarStyle = $event),
            disabled: !$setup.theme.showTabs
          }, {
            default: _withCtx(() => [_createVNode(_component_el_option, {
              key: "card",
              label: $setup.translateTitle('卡片'),
              value: "card"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "smart",
              label: $setup.translateTitle('灵动'),
              value: "smart"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "smooth",
              label: $setup.translateTitle('圆滑'),
              value: "smooth"
            }, null, 8
            /* PROPS */
            , ["label"])]),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["modelValue", "disabled"])]),
          _: 1
          /* STABLE */

        }), _createVNode(_component_el_form_item, null, {
          label: _withCtx(() => [_createTextVNode(_toDisplayString($setup.translateTitle('分栏风格')) + " ", 1
          /* TEXT */
          ), _createVNode(_component_el_tooltip, {
            content: $setup.translateTitle('分栏布局时生效'),
            effect: "dark",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_vab_icon, {
              icon: "question-line"
            })]),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["content"])]),
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: $setup.theme.columnStyle,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $setup.theme.columnStyle = $event),
            disabled: $setup.theme.layout !== 'column'
          }, {
            default: _withCtx(() => [_createVNode(_component_el_option, {
              key: "vertical",
              label: $setup.translateTitle('纵向'),
              value: "vertical"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "horizontal",
              label: $setup.translateTitle('横向'),
              value: "horizontal"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "card",
              label: $setup.translateTitle('卡片'),
              value: "card"
            }, null, 8
            /* PROPS */
            , ["label"]), _createVNode(_component_el_option, {
              key: "arrow",
              label: $setup.translateTitle('箭头'),
              value: "arrow"
            }, null, 8
            /* PROPS */
            , ["label"])]),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["modelValue", "disabled"])]),
          _: 1
          /* STABLE */

        }), _createVNode(_component_el_form_item, {
          label: $setup.translateTitle('头部固定')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: $setup.theme.fixedHeader,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $setup.theme.fixedHeader = $event),
            disabled: $setup.theme.layout === 'common'
          }, null, 8
          /* PROPS */
          , ["modelValue", "disabled"])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"]), _createVNode(_component_el_form_item, {
          label: $setup.translateTitle('国际化')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: $setup.theme.showLanguage,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $setup.theme.showLanguage = $event)
          }, null, 8
          /* PROPS */
          , ["modelValue"])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"]), _createVNode(_component_el_form_item, {
          label: $setup.translateTitle('进度条')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: $setup.theme.showProgressBar,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => $setup.theme.showProgressBar = $event)
          }, null, 8
          /* PROPS */
          , ["modelValue"])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"]), _createVNode(_component_el_form_item, {
          label: $setup.translateTitle('刷新')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: $setup.theme.showRefresh,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => $setup.theme.showRefresh = $event)
          }, null, 8
          /* PROPS */
          , ["modelValue"])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"]), _createVNode(_component_el_form_item, {
          label: $setup.translateTitle('搜索')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: $setup.theme.showSearch,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => $setup.theme.showSearch = $event)
          }, null, 8
          /* PROPS */
          , ["modelValue"])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"]), _createVNode(_component_el_form_item, {
          label: $setup.translateTitle('通知')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: $setup.theme.showNotice,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => $setup.theme.showNotice = $event)
          }, null, 8
          /* PROPS */
          , ["modelValue"])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"]), _createVNode(_component_el_form_item, {
          label: $setup.translateTitle('全屏')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: $setup.theme.showFullScreen,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => $setup.theme.showFullScreen = $event)
          }, null, 8
          /* PROPS */
          , ["modelValue"])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"]), _createVNode(_component_el_form_item, {
          label: $setup.translateTitle('锁屏')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: $setup.theme.showLock,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => $setup.theme.showLock = $event)
          }, null, 8
          /* PROPS */
          , ["modelValue"])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"]), _createVNode(_component_el_form_item, {
          label: $setup.translateTitle('页面动画')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: $setup.theme.showPageTransition,
            "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => $setup.theme.showPageTransition = $event)
          }, null, 8
          /* PROPS */
          , ["modelValue"])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"])]),
        _: 1
        /* STABLE */

      }, 8
      /* PROPS */
      , ["model"])]),
      _: 1
      /* STABLE */

    })]),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["modelValue", "title"]);
}