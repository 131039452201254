/**
 * @description 导出网络配置
 **/
module.exports = {
  // 默认的接口地址，开发环境和生产环境都会走/vab-mock-server
  // 正式项目可以选择自己配置成需要的接口地址，如"http://hf.lx-obj.top/api/"
  // 问号后边代表开发环境，冒号后边代表生产环境
  // baseURL:
  //   process.env.NODE_ENV === 'development'
  //      ? 'http://hf.lx-obj.top'
  //     // :'http://hf-server.taideos.com',//泰德
  //   // : 'https://hf-service.shanhainengyuan.com',//山海正式
  //       //:'http://hf.lx-obj.top',//山海测试
  //     // ? 'http://hf-text.lx-obj.top'//zhuying
  //     : 'http://hf.lx-obj.top',//测试
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  // 配后端数据的接收方式 或 application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/json;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 30000,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200, 0, '200', '0'],
  // 数据状态的字段名称
  statusName: 'code',
  // 状态信息的字段名称
  messageName: 'msg',
}
