import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_header = _resolveComponent("vab-header");

  const _component_vab_side_bar = _resolveComponent("vab-side-bar");

  const _component_vab_tabs = _resolveComponent("vab-tabs");

  const _component_vab_app_main = _resolveComponent("vab-app-main");

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vab-layout-common", {
      fixed: $props.fixedHeader,
      'no-tabs-bar': !$props.showTabs
    }])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["vab-layout-header", {
      'fixed-header': $props.fixedHeader
    }])
  }, [_createVNode(_component_vab_header, {
    layout: "common"
  }), _createElementVNode("div", null, [_createVNode(_component_vab_side_bar, {
    layout: "common"
  }), _withDirectives(_createElementVNode("div", {
    class: _normalizeClass(["vab-main", {
      'is-collapse-main': $props.collapse
    }])
  }, [_createVNode(_component_vab_tabs, {
    layout: "common"
  })], 2
  /* CLASS */
  ), [[_vShow, $props.showTabs]])])], 2
  /* CLASS */
  ), _createElementVNode("div", {
    class: _normalizeClass(["vab-main main-padding", {
      'is-collapse-main': $props.collapse
    }])
  }, [_createVNode(_component_vab_app_main)], 2
  /* CLASS */
  )], 2
  /* CLASS */
  );
}