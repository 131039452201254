import request from '@/utils/request'
// 客户列表
export function getCustomerList(params) {
  return request({
    url: '/api/system/customer/index',
    method: 'get',
    params,
  })
}

// 添加客户
export function doAdd(data) {
  return request({
    url: '/api/system/customer/add',
    method: 'post',
    data,
  })
}
// 编辑客户
export function doEdit(data) {
  return request({
    url: '/api/system/customer/edit',
    method: 'post',
    data,
  })
}
// 删除客户
export function doDelete(params) {
  return request({
    url: '/api/system/customer/del',
    method: 'get',
    params,
  })
}
//快速修改
export function update_field(params) {
  return request({
    url: '/api/system/customer/update_field',
    method: 'post',
    params,
  })
}

//查看
export function get_infos(params) {
  return request({
    url: '/api/system/customer/get_info',
    method: 'get',
    params,
  })
}
// 实名认证
export function get_verify_url(params) {
  return request({
    url: '/api/system/customer/get_verify_url',
    method: 'get',
    params,
  })
}
// 修改密码
export function modify_password(data) {
  return request({
    url: '/api/system/customer/modify_password',
    method: 'post',
    data,
  })
}
//公共修改密码
export function modify_passwords(data) {
  return request({
    url: '/api/system/common/modify_password',
    method: 'post',
    data,
  })
}
// 新增客户统计

export function new_user_stat(params) {
  return request({
    url: '/api/system/customer/new_user_stat',
    method: 'get',
    params,
  })
}
// 获取产品列表
export function get_product_list(params) {
  return request({
    url: '/api/system/customer/get_product_list',
    method: 'get',
    params,
  })
}
