import request from '@/utils/request'
// 消息模板列表
export function getMessageList(params) {
  return request({
    url: '/api/system/message/index',
    method: 'get',
    params,
  })
}
// 详情
export function get_infos(params) {
  return request({
    url: '/api/system/message/get_info',
    method: 'get',
    params,
  })
}
// 添加、
export function doAdd(data) {
  return request({
    url: '/api/system/message/add',
    method: 'post',
    data,
  })
}
// 编辑
export function doEdit(data) {
  return request({
    url: '/api/system/message/edit',
    method: 'post',
    data,
  })
}
// 删除
export function doDelete(params) {
  return request({
    url: '/api/system/message/del',
    method: 'get',
    params,
  })
}
// 消息列表，接收端
export function get_message_list(params) {
  return request({
    url: '/api/system/message/get_message_list',
    method: 'get',
    params,
  })
}
export function update_field(params) {
  return request({
    url: '/api/system/message/update_field',
    method: 'post',
    params,
  })
}

// 消息模板列表
export function clear_all_meg(params) {
  return request({
    url: '/api/system/message/clear_all_meg',
    method: 'get',

  })
}

// 消息列表，接收端
export function get_notify(params) {
  return request({
    url: '/api/system/message/get_notify',
    method: 'post',
    params,
  })
}
// 获取拓客信息未分配数量
export function get_toker_data_count(params) {
  return request({
    url: '/api/system/toker/get_toker_data_count',
    method: 'get',
    params
  })
}
