/* unplugin-vue-components disabled */import { useSettingsStore } from '@/store/modules/settings';
export default {
  __name: 'index',

  setup(__props, {
    expose
  }) {
    expose();
    const $pub = inject('$pub');
    const settingsStore = useSettingsStore();
    const {
      theme
    } = storeToRefs(settingsStore);

    const handleOpenTheme = () => {
      $pub('theme');
    };

    const __returned__ = {
      $pub,
      settingsStore,
      theme,
      handleOpenTheme,
      useSettingsStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};