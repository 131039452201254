/* unplugin-vue-components disabled */import { useSettingsStore } from '@/store/modules/settings';
import { translateTitle } from '@/utils/i18n';
import _ from 'lodash';
export default {
  __name: 'VabThemeDrawer',

  setup(__props, {
    expose
  }) {
    expose();
    const $sub = inject('$sub');
    const $unsub = inject('$unsub');
    const $baseLoading = inject('$baseLoading');
    const settingsStore = useSettingsStore();
    const {
      theme,
      device
    } = storeToRefs(settingsStore);
    const {
      saveTheme,
      resetTheme,
      updateTheme
    } = settingsStore;
    const state = reactive({
      drawerVisible: false
    });

    const handleOpenTheme = () => {
      state.drawerVisible = true;
    };

    const setDefaultTheme = async () => {
      await resetTheme();
      await updateTheme();
      state.drawerVisible = false;
    };

    const handleSaveTheme = async () => {
      await saveTheme();
      state.drawerVisible = false;
    };

    const randomTheme = async () => {
      const loading = $baseLoading(0); // 随机换肤重置移除主题，防止代码更新影响样式

      await resetTheme();
      const themeNameArray = ['blue-black', 'blue-white', 'ocean', 'green-black', 'green-white', 'purple-black', 'purple-white', 'red-black', 'red-white'];
      theme.value.themeName = _.shuffle(_.pull(themeNameArray, [theme.value.themeName]))[0];
      const columnStyleArray = ['vertical', 'horizontal', 'card', 'arrow'];
      theme.value.columnStyle = _.shuffle(_.pull(columnStyleArray, [theme.value.columnStyle]))[0];
      const backgroundArray = ['none', 'vab-background'];
      theme.value.background = _.shuffle(_.pull(backgroundArray, [theme.value.background]))[0];
      const tabsBarStyleArray = ['card', 'smart', 'smooth'];
      theme.value.tabsBarStyle = _.shuffle(_.pull(tabsBarStyleArray, [theme.value.tabsBarStyle]))[0];
      const showTabsIconArray = [true, false];
      theme.value.showTabsIcon = _.shuffle(_.pull(showTabsIconArray, [theme.value.showTabsIcon]))[0];

      if (device.value === 'desktop') {
        const layoutArray = ['horizontal', 'vertical', 'column', 'comprehensive', 'common', 'float'];
        theme.value.layout = _.shuffle(_.pull(layoutArray, [theme.value.layout]))[0];
      } else theme.value.layout = 'vertical';

      await updateTheme();
      await saveTheme();
      setTimeout(() => {
        loading.close();
      }, 1000);
    };

    $sub('theme', () => {
      handleOpenTheme();
    });
    $sub('random-theme', () => {
      randomTheme();
    });
    onBeforeUnmount(() => {
      $unsub('theme');
      $unsub('random-theme');
    });
    const __returned__ = {
      $sub,
      $unsub,
      $baseLoading,
      settingsStore,
      theme,
      device,
      saveTheme,
      resetTheme,
      updateTheme,
      state,
      handleOpenTheme,
      setDefaultTheme,
      handleSaveTheme,
      randomTheme,
      useSettingsStore,
      translateTitle,
      _
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};