export default {
  __name: 'index',
  props: {
    collapse: {
      type: Boolean,

      default() {
        return false;
      }

    },
    fixedHeader: {
      type: Boolean,

      default() {
        return true;
      }

    },
    showTabs: {
      type: Boolean,

      default() {
        return true;
      }

    },
    device: {
      type: String,

      default() {
        return 'desktop';
      }

    }
  },

  setup(__props, {
    expose
  }) {
    expose();
    const __returned__ = {};
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};