/* unplugin-vue-components disabled */import "core-js/modules/es.array.push.js";
import { useTabsStore } from '@/store/modules/tabs';
import { useRoutesStore } from '@/store/modules/routes';
import { useSettingsStore } from '@/store/modules/settings';
import { translateTitle } from '@/utils/i18n';
import { handleActivePath, handleTabs } from '@/utils/routes';
export default {
  __name: 'index',
  props: {
    layout: {
      type: String,
      default: ''
    }
  },

  setup(__props, {
    expose
  }) {
    expose();
    const route = useRoute();
    const router = useRouter();
    const settingsStore = useSettingsStore();
    const {
      theme
    } = storeToRefs(settingsStore);
    const routesStore = useRoutesStore();
    const {
      getRoutes: routes
    } = storeToRefs(routesStore);
    const tabsStore = useTabsStore();
    const {
      getVisitedRoutes: visitedRoutes
    } = storeToRefs(tabsStore);
    const {
      addVisitedRoute,
      delVisitedRoute,
      delOthersVisitedRoutes,
      delLeftVisitedRoutes,
      delRightVisitedRoutes,
      delAllVisitedRoutes
    } = tabsStore;
    const tabActive = ref('');
    const active = ref(false);
    const hoverRoute = ref();
    const visible = ref(false);
    const top = ref(0);
    const left = ref(0);

    const isActive = path => path === handleActivePath(route, true);

    const isNoCLosable = tag => tag.meta && tag.meta.noClosable;

    const handleTabClick = tab => {
      if (!isActive(tab.name)) router.push(visitedRoutes.value[tab.index]);
    };

    const handleVisibleChange = val => {
      active.value = val;
    };

    const initNoCLosableTabs = routes => {
      routes.forEach(_route => {
        if (_route.meta && _route.meta.noClosable) addTabs(_route);
        if (_route.children) initNoCLosableTabs(_route.children);
      });
    };
    /**
     * 添加标签页
     * @param tag route
     * @returns {Promise<void>}
     */


    const addTabs = async tag => {
      const tab = handleTabs(tag);

      if (tab) {
        await addVisitedRoute(tab);
        tabActive.value = tab.path;
      }
    };
    /**
     * 根据原生路径删除标签中的标签
     * @param rawPath 原生路径
     * @returns {Promise<void>}
     */


    const handleTabRemove = async rawPath => {
      if (isActive(rawPath)) await toLastTab();
      await delVisitedRoute(rawPath);
    };

    const handleCommand = command => {
      switch (command) {
        case 'closeOthersTabs':
          closeOthersTabs();
          break;

        case 'closeLeftTabs':
          closeLeftTabs();
          break;

        case 'closeRightTabs':
          closeRightTabs();
          break;

        case 'closeAllTabs':
          closeAllTabs();
          break;
      }
    };
    /**
     * 删除其他标签页
     * @returns {Promise<void>}
     */


    const closeOthersTabs = async () => {
      if (hoverRoute.value) {
        await router.push(hoverRoute.value);
        await delOthersVisitedRoutes(hoverRoute.value.path);
      } else await delOthersVisitedRoutes(handleActivePath(route, true));

      await closeMenu();
    };
    /**
     * 删除左侧标签页
     * @returns {Promise<void>}
     */


    const closeLeftTabs = async () => {
      if (hoverRoute.value) {
        await router.push(hoverRoute.value);
        await delLeftVisitedRoutes(hoverRoute.value.path);
      } else await delLeftVisitedRoutes(handleActivePath(route, true));

      await closeMenu();
    };
    /**
     * 删除右侧标签页
     * @returns {Promise<void>}
     */


    const closeRightTabs = async () => {
      if (hoverRoute.value) {
        await router.push(hoverRoute.value);
        await delRightVisitedRoutes(hoverRoute.value.path);
      } else await delRightVisitedRoutes(handleActivePath(route, true));

      await closeMenu();
    };
    /**
     * 删除所有标签页
     * @returns {Promise<void>}
     */


    const closeAllTabs = async () => {
      await delAllVisitedRoutes();
      await toLastTab();
      await closeMenu();
    };
    /**
     * 跳转最后一个标签页
     */


    const toLastTab = async () => {
      const latestView = visitedRoutes.value.filter(_ => _.path !== handleActivePath(route, true)).slice(-1)[0];
      if (latestView) await router.push(latestView);else await router.push('/');
    };

    const {
      x,
      y
    } = useMouse();

    const openMenu = () => {
      left.value = x.value;
      top.value = y.value;
      visible.value = true;
    };

    const closeMenu = () => {
      visible.value = false;
      hoverRoute.value = null;
    };

    initNoCLosableTabs(routes.value);
    watch(() => route.fullPath, () => {
      addTabs(route);
    }, {
      immediate: true
    });
    watchEffect(() => {
      if (visible.value) document.body.addEventListener('click', closeMenu);else document.body.removeEventListener('click', closeMenu);
    });
    const __returned__ = {
      route,
      router,
      settingsStore,
      theme,
      routesStore,
      routes,
      tabsStore,
      visitedRoutes,
      addVisitedRoute,
      delVisitedRoute,
      delOthersVisitedRoutes,
      delLeftVisitedRoutes,
      delRightVisitedRoutes,
      delAllVisitedRoutes,
      tabActive,
      active,
      hoverRoute,
      visible,
      top,
      left,
      isActive,
      isNoCLosable,
      handleTabClick,
      handleVisibleChange,
      initNoCLosableTabs,
      addTabs,
      handleTabRemove,
      handleCommand,
      closeOthersTabs,
      closeLeftTabs,
      closeRightTabs,
      closeAllTabs,
      toLastTab,
      x,
      y,
      openMenu,
      closeMenu,
      useTabsStore,
      useRoutesStore,
      useSettingsStore,
      translateTitle,
      handleActivePath,
      handleTabs
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};