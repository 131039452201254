/* unplugin-vue-components disabled */import { useSettingsStore } from '@/store/modules/settings';
export default {
  __name: 'index',

  setup(__props, {
    expose
  }) {
    expose();
    const {
      isFullscreen,
      toggle
    } = useFullscreen();
    const settingsStore = useSettingsStore();
    const {
      theme
    } = storeToRefs(settingsStore);
    const __returned__ = {
      isFullscreen,
      toggle,
      settingsStore,
      theme,
      useSettingsStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};