/* unplugin-vue-components disabled */import "core-js/modules/es.array.push.js";
import { useUserStore } from '@/store/modules/user';
import { toLoginRoute } from '@/utils/routes';
import { translateTitle } from '@/utils/i18n';
import { modify_passwords } from '@/api/customer';
export default {
  __name: 'index',

  setup(__props, {
    expose
  }) {
    expose();
    const route = useRoute();
    const router = useRouter();
    const $baseMessage = inject('$baseMessage');
    const userStore = useUserStore();
    const {
      avatar,
      username
    } = storeToRefs(userStore);
    const {
      id,
      identity
    } = useUserStore();
    console.log(id);
    const {
      logout
    } = userStore;
    const visible = ref(false);
    const active = ref(false);
    const show = ref(false);
    const form = ref({
      password: ''
    });
    onMounted(() => {
      setTimeout(() => {
        show.value = true;
      }, 10000); // form.value.password=''
    });

    const handleVisibleChange = val => {
      active.value = val;
    };

    const handleCommand = async command => {
      switch (command) {
        case 'logout':
          await logout();
          await router.push('/index');
          break;

        case 'personalCenter':
          await router.push('/setting/personalCenter');
          break;
      }
    };

    const editPass = () => {
      modify_passwords({
        id: id,
        password: form.value.password
      }).then(res => {
        if (res.code == 200) {
          $baseMessage(res.msg, 'success', 'vab-hey-message-success');
          visible.value = false;
        } else {
          $baseMessage(res.msg, 'error', 'vab-hey-message-error');
        }
      });
    };

    const close = () => {
      visible.value = false;
      form.value.password = '';
    };

    const __returned__ = {
      route,
      router,
      $baseMessage,
      userStore,
      avatar,
      username,
      id,
      identity,
      logout,
      visible,
      active,
      show,
      form,
      handleVisibleChange,
      handleCommand,
      editPass,
      close,
      useUserStore,
      toLoginRoute,
      translateTitle,
      modify_passwords
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};