/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import { useAclStore } from './acl';
import { useTabsStore } from './tabs';
import { useRoutesStore } from './routes';
import { useSettingsStore } from './settings';
import { getUserInfo, login, logout, socialLogin } from '@/api/user';
import { getToken, removeToken, setToken, setIdentity, getIdentity, getID, setID, getOpen, setOpen, getSuper, setSuper, getToker, setToker, getRole, setRole } from '@/utils/token';
import { resetRouter } from '@/router';
import { isArray, isString } from '@/utils/validate';
import { tokenName } from '@/config';
import { gp } from '@gp';
import { homepage } from '@/api/index';
export const useUserStore = defineStore('user', {
  state: () => ({
    token: getToken(),
    str: '',
    id: getID(),
    open: getOpen(),
    supers: getSuper(),
    identity: getIdentity(),
    is_toker_count: getToker(),
    role: getRole(),
    username: '游客',
    avatar: 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif'
  }),
  getters: {
    getToken: state => state.token,
    getUsername: state => state.username,
    getID: state => state.id,
    getAgentid: state => state.agent_id,
    getFullname: state => state.full_name,
    getStr: state => state.str,
    getIdentity: state => state.identity,
    getOpen: state => state.open,
    getAvatar: state => state.avatar,
    getSuper: state => state.supers,
    getToker: state => state.is_toker_count,
    getRole: state => state.role
  },
  actions: {
    /**
     * @description 设置token
     * @param {*} token
     */
    setToken(token) {
      this.token = token;
      setToken(token);
    },

    /**
     * @description 设置用户名
     * @param {*} username
     */
    setUsername(username) {
      this.username = username;
    },

    /**
     * @description 设置名称
     * @param {*}  Fullname
     */
    setFullname(full_name) {
      this.full_name = full_name;
    },

    /**
     * @description 设置txt
     * @param {*} str
     */
    setStr(str) {
      this.str = str;
    },

    /**
     * @description 设置id
     * @param {*} id
     */
    setID(id) {
      this.id = id;
      setID(id);
      console.log(id);
    },

    /**
     * @description 设置agent_id
     * @param {*} agent_id
     */
    setAgentid(agent_id) {
      this.agent_id = agent_id;
      console.log(agent_id);
    },

    /**
     * @description 设置identity
     * @param {*} identity
     */
    setIdentity(identity) {
      this.identity = identity;
      setIdentity(identity);
    },

    /**
     * @description 设置open
     * @param {*} open
     */
    setOpen(open) {
      this.open = open;
      setOpen(open);
    },

    /**
     * @description 设置toker
     * @param {*} toker
     */
    setToker(toker) {
      this.is_toker_count = toker;
      setToker(toker);
    },

    /**
     * @description 设置role
     * @param {*} role
     */
    setRole(role) {
      this.role = role;
      setRole(role);
    },

    /**
     * @description 设置头像
     * @param {*} avatar
     */
    setAvatar(avatar) {
      this.avatar = avatar;
    },

    /**
     * @description 设置super_user
     * @param {*} super_user
     */
    setSuper(supers) {
      this.supers = supers;
      setSuper(supers);
    },

    /**
     * @description 登录拦截放行时，设置虚拟角色
     */
    setVirtualRoles() {
      const aclStore = useAclStore();
      aclStore.setFull(true);
      this.setUsername('admin(未开启登录拦截)');
      this.setAvatar('https://i.gtimg.cn/club/item/face/img/2/15922_100.gif');
    },

    /**
     * @description 设置token并发送提醒
     * @param {string} token 更新令牌
     * @param {string} tokenName 令牌名称
     */
    afterLogin(token, tokenName) {
      const settingsStore = useSettingsStore();
      console.log(settingsStore);

      if (token) {
        this.setToken(token);
        const hour = new Date().getHours();
        const thisTime = hour < 8 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 18 ? '下午好' : '晚上好';
        gp.$baseNotify(`欢迎登录${settingsStore.title}`, `${thisTime}！`);
      } else {
        const err = `登录接口异常，未正确返回${tokenName}...`;
        gp.$baseMessage(err, 'error', 'vab-hey-message-error');
        throw err;
      }
    },

    /**
     * @description 登录
     * @param {*} userInfo
     */
    async login(userInfo) {
      const {
        data: {
          [tokenName]: token
        }
      } = await login(userInfo);
      this.afterLogin(token, tokenName);
    },

    /**
     * @description 第三方登录
     * @param {*} tokenData
     */
    async socialLogin(tokenData) {
      const {
        data: {
          [tokenName]: token
        }
      } = await socialLogin(tokenData);
      this.afterLogin(token, tokenName);
    },

    /**
     * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
     * @returns
     */
    async getUserInfo() {
      const {
        data: {
          username,
          id,
          identity,
          agent_id,
          full_name,
          is_open_yw,
          super_user,
          is_toker_count,
          role
        }
      } = await getUserInfo(); // *
      //  * 检验返回数据是否正常，无对应参数，将使用默认用户名,头像,Roles和Permissions
      //  * username {String}
      //  * avatar {String}
      //  * roles {List}
      //  * ability {List}
      // const aclStore = useAclStore()

      this.setUsername(username);
      this.setFullname(full_name);
      this.setID(id);
      this.setAgentid(agent_id);
      this.setIdentity(identity);
      this.setOpen(is_open_yw);
      this.setToker(is_toker_count);
      this.setSuper(super_user);
      this.setRole(role); // homepage().then((res) => {
      //   if(res.data!=undefined){
      //     this.setStr(res.data.welcome.str)
      //   }
      // })
      // this.setAvatar('https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
      // var list = ['Admin']
      // aclStore.setRole(list)
      //  if (
      //    (username && !isString(username)) ||
      //    (avatar && !isString(avatar)) ||
      //    (roles && !isArray(roles)) ||
      //    (permissions && !isArray(permissions))
      //  ) {
      //  const err = 'getUserInfo核心接口异常，请检查返回JSON格式是否正确'
      //    gp.$baseMessage(err, 'error', 'vab-hey-message-error')
      //    throw err
      //  } else {
      //   const aclStore = useAclStore()
      //   //如不使用username用户名,可删除以下代码
      //   if (username) this.setUsername(username)
      //   // 如不使用avatar头像,可删除以下代码
      //   if (avatar) this.setAvatar(avatar)
      //   //如不使用roles权限控制,可删除以下代码
      //   if (roles) aclStore.setRole(roles)
      //   // 如不使用permissions权限控制,可删除以下代码
      //   // if (permissions) aclStore.setPermission(permissions)
      // }
    },

    /**
     * @description 退出登录
     */
    async logout() {
      var form = {
        id: this.id,
        identity: this.identity
      };
      await logout(form);
      await this.resetAll();
    },

    /**
     * @description 重置token、roles、permission、router、tabsBar等
     */
    async resetAll() {
      this.setToken(null);
      this.setUsername('游客');
      this.setAvatar('https://i.gtimg.cn/club/item/face/img/2/15922_100.gif');
      const aclStore = useAclStore();
      const routesStore = useRoutesStore();
      const tabsStore = useTabsStore();
      aclStore.setFull(false);
      aclStore.setRole([]);
      aclStore.setPermission([]);
      routesStore.clearRoutes();
      tabsStore.delAllVisitedRoutes();
      await resetRouter();
      removeToken();
    }

  }
});