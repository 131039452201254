import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_icon = _resolveComponent("vab-icon");

  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

  return _openBlock(), _createBlock(_component_el_breadcrumb, {
    class: "vab-breadcrumb",
    separator: ">"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.breadcrumbList, (item, index) => {
      return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
        key: index,
        to: $setup.handleTo(item.redirect)
      }, {
        default: _withCtx(() => [item.meta && item.meta.icon ? (_openBlock(), _createBlock(_component_vab_icon, {
          key: 0,
          icon: item.meta.icon
        }, null, 8
        /* PROPS */
        , ["icon"])) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString($setup.translateTitle(item.meta.title)), 1
        /* TEXT */
        )]),
        _: 2
        /* DYNAMIC */

      }, 1032
      /* PROPS, DYNAMIC_SLOTS */
      , ["to"]);
    }), 128
    /* KEYED_FRAGMENT */
    ))]),
    _: 1
    /* STABLE */

  });
}