import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-5274904a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "notice-list"
};
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_icon = _resolveComponent("vab-icon");

  const _component_el_avatar = _resolveComponent("el-avatar");

  const _component_el_scrollbar = _resolveComponent("el-scrollbar");

  const _component_el_tab_pane = _resolveComponent("el-tab-pane");

  const _component_el_tabs = _resolveComponent("el-tabs");

  const _component_el_button = _resolveComponent("el-button");

  const _component_el_popover = _resolveComponent("el-popover");

  const _component_el_badge = _resolveComponent("el-badge");

  return $setup.theme.showNotice ? (_openBlock(), _createBlock(_component_el_badge, {
    key: 0,
    type: "danger",
    value: $setup.badge
  }, {
    default: _withCtx(() => [_createVNode(_component_el_popover, {
      placement: "bottom",
      trigger: "hover",
      width: 300
    }, {
      reference: _withCtx(() => [_createVNode(_component_vab_icon, {
        icon: "notification-line"
      })]),
      default: _withCtx(() => [_createVNode(_component_el_tabs, {
        modelValue: $setup.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.activeName = $event),
        onTabClick: $setup.handleClick
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: $setup.translateTitle('通知'),
          name: "notice"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_scrollbar, null, {
            default: _withCtx(() => [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.notices, (item, index) => {
              return _openBlock(), _createElementBlock("li", {
                key: index
              }, [_createVNode(_component_el_avatar, {
                size: 45,
                src: item.image
              }, null, 8
              /* PROPS */
              , ["src"]), _createElementVNode("span", {
                innerHTML: item.content
              }, null, 8
              /* PROPS */
              , _hoisted_2)]);
            }), 128
            /* KEYED_FRAGMENT */
            ))])]),
            _: 1
            /* STABLE */

          })])]),
          _: 1
          /* STABLE */

        }, 8
        /* PROPS */
        , ["label"]), _createCommentVNode(" <el-tab-pane :label=\"translateTitle('邮件')\" name=\"email\">\n          <div class=\"notice-list\">\n            <el-scrollbar>\n              <ul>\n                <li v-for=\"(item, index) in notices\" :key=\"index\">\n                  <el-avatar :size=\"45\" :src=\"item.image\" />\n                  <span>{{ item.email }}</span>\n                </li>\n              </ul>\n            </el-scrollbar>\n          </div>\n        </el-tab-pane> ")]),
        _: 1
        /* STABLE */

      }, 8
      /* PROPS */
      , ["modelValue"]), _createElementVNode("div", {
        class: "notice-clear",
        onClick: $setup.handleClearNotice
      }, [_createVNode(_component_el_button, {
        type: "primary",
        link: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_vab_icon, {
          icon: "close-circle-line"
        }), _createElementVNode("span", null, _toDisplayString($setup.translateTitle('清空消息')), 1
        /* TEXT */
        )]),
        _: 1
        /* STABLE */

      })])]),
      _: 1
      /* STABLE */

    })]),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["value"])) : _createCommentVNode("v-if", true);
}