/**
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import { useUserStore } from '@/store/modules/user'
import { useRoutesStore } from '@/store/modules/routes'
import { useSettingsStore } from '@/store/modules/settings'
import VabProgress from 'nprogress'
import 'nprogress/nprogress.css'
import getPageTitle from '@/utils/pageTitle'
import { toLoginRoute } from '@/utils/routes'
import {
  authentication,
  loginInterception,
  routesWhiteList,
  supportVisit,
} from '@/config'

export function setupPermissions(router) {
  VabProgress.configure({
    easing: 'ease',
    speed: 500,
    trickleSpeed: 200,
    showSpinner: false,
  })
  router.beforeEach(async (to, from, next) => {
    var result=[];
    if(to.name!='Login'){
      var url=window.location.href
      var querys = url.substring(url.indexOf('?') + 1).split('&');
    
            for(var i=0;i<querys.length;i++){
                var temp=querys[i].split('=');
                if(temp.length<2){
                    result[temp[0]]='';
                }else{
                    result[temp[0]]=temp[1];
                }
            }
    }else{
      result.token='';
    }

    const {
      getTheme: { showProgressBar },
    } = useSettingsStore()
    const { routes, setRoutes } = useRoutesStore()
    const { token, getUserInfo, setVirtualRoles,setToken, setIdentity,resetAll } = useUserStore()

     console.log(token)
    if (showProgressBar) VabProgress.start()
    var hasToken=''
   if(result.token!=undefined&&result.token!=''){
      hasToken =result.token
       setToken(result.token)
       setIdentity('system')
   }else{
      hasToken = token
   }

    if (!loginInterception) hasToken = true

    if (hasToken) {
      if (routes.length) {
        // 禁止已登录用户返回登录页
        if (to.path === '/login') {
          next({ path: '/' })
          if (showProgressBar) VabProgress.done()
        } else {
          next()
        }
      } else {
        try {
          // 开启登陆拦截
          if (loginInterception) await getUserInfo()
          // config/setting.config.js loginInterception为false(关闭登录拦截时)时，创建虚拟角色
          else await setVirtualRoles()
          // 未开登陆拦截
          // await setVirtualRoles()
          // 根据路由模式获取路由并根据权限过滤
          await setRoutes(authentication)
          next({ ...to, replace: true })
        } catch (err) {
          console.error('vue-admin-beautiful错误拦截:', err)
          await resetAll()
          next(toLoginRoute(to.path))
        }
      }
    } else {
        // console.log('路由守路由守卫路由守卫路由守卫路由守卫路由守卫路由守卫路由守卫路由守卫路由守卫路由守卫卫')
        // console.log(routes)
      if (routesWhiteList.includes(to.path)) {
        // 设置游客路由(不需要可以删除)
        if (supportVisit && !routes.length) {
          await setRoutes('visit')
          next({ path: to.path, replace: true })
        } else next()
      } else next(toLoginRoute(to.path))
    }
  })
  router.afterEach((to) => {
    document.title = getPageTitle(to.meta.title)
    if (VabProgress.status) VabProgress.done()
  })
}
