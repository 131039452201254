/* unplugin-vue-components disabled */import { useSettingsStore } from '@/store/modules/settings';
import { translateTitle } from '@/utils/i18n';
export default {
  __name: 'VabThemeSetting',

  setup(__props, {
    expose
  }) {
    expose();
    const $pub = inject('$pub');
    const $baseMessage = inject('$baseMessage');
    const settingsStore = useSettingsStore();
    const {
      theme
    } = storeToRefs(settingsStore);

    const handleOpenTheme = () => {
      $pub('theme');
    };

    const randomTheme = () => {
      $pub('random-theme');
    };

    const buy = () => {
      window.open('https://vue-admin-beautiful.com/authorization');
    };

    const getCode = () => {
      if (process.env.NODE_ENV === 'development') $pub('get-code');else ElMessageBox.prompt('请输入密码(密码在购买时获得，跳转后需登录购买时绑定的github账号)', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({
        value
      }) => {
        if (value !== 'vabp') {
          $baseMessage('密码不正确！', 'error', 'vab-hey-message-error');
          return;
        }

        $pub('get-code');
      }).catch(() => {});
    };

    const removeLocalStorage = () => {
      localStorage.clear();
      location.reload();
    };

    const __returned__ = {
      $pub,
      $baseMessage,
      settingsStore,
      theme,
      handleOpenTheme,
      randomTheme,
      buy,
      getCode,
      removeLocalStorage,
      useSettingsStore,
      translateTitle
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};