/* unplugin-vue-components disabled */import { useRoutesStore } from '@/store/modules/routes';
import { handleActivePath } from '@/utils/routes';
import { ElNotification } from 'element-plus';
import { get_notify } from '@/api/message';
export default {
  __name: 'index',

  setup(__props, {
    expose
  }) {
    expose();
    var myVar;

    const open = async () => {
      const {
        data
      } = await get_notify();

      if (data != null) {
        data.forEach(function (item, index) {
          setTimeout(() => {
            //需要定时执行的代码
            ElNotification({
              title: item.title,
              message: item.content,
              showClose: false,
              position: 'bottom-right',
              dangerouslyUseHTMLString: true,
              type: 'warning',
              duration: 8000
            });
          }, (index + 1) * 300);
        });
        myVar = setTimeout(() => {
          //需要定时执行的代码
          open();
        }, 10000);
      } else {
        clearTimeout(myVar);
      }
    };

    onMounted(() => {
      setTimeout(() => {
        //需要定时执行的代码
        open();
      }, 10000);
    });
    onUnmounted(() => {
      clearTimeout(myVar);
    }); //已废弃 destroyed(() => {
    //    clearTimeout(myVar)
    // })

    const route = useRoute();
    const routesStore = useRoutesStore();
    const {
      tab,
      activeMenu
    } = storeToRefs(routesStore);
    watch(route, () => {
      if (tab.value.data !== route.matched[0].name) tab.value.data = route.matched[0].name;
      activeMenu.value.data = handleActivePath(route);
    }, {
      immediate: true
    });
    const __returned__ = {
      myVar,
      open,
      route,
      routesStore,
      tab,
      activeMenu,
      useRoutesStore,
      handleActivePath,
      ElNotification,
      get_notify
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};