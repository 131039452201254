// 首页
import request from '@/utils/request'

export async function homepage(data) {
  return request({
    url: '/api/system/statistic/homepage',
    method: 'post',
    data,
  })
}
export async function welcomes(data) {
  return request({
    url: '/api/system/statistic/welcome',
    method: 'post',
    data,
  })
}
export async function user_line(data) {
  return request({
    url: '/api/system/statistic/user_line',
    method: 'post',
    data,
  })
}
export async function user_data(data) {
  return request({
    url: '/api/system/statistic/user_data',
    method: 'post',
    data,
  })
}

export async function user_area(data) {
  return request({
    url: '/api/system/statistic/user_area',
    method: 'post',
    data,
  })
}

export async function start_page(data) {
  return request({
    url: '/api/system/index/start_page',
    method: 'post',
    data,
  })
}
// 业务首页
export async function industry_data(data) {
  return request({
    url: '/api/system/statistic/industry_data',
    method: 'post',
    data,
  })
}
// 工商首页
export async function industry_index(params) {
  return request({
    url: '/api/system/target/industry_index',
    method: 'get',
    params,
  })
}

// 客户数量统计
export async function industry_customer_data(data) {
  return request({
    url: '/api/system/statistic/industry_customer_data',
    method: 'post',
    data,
  })
}
// 施工完成统计
export async function industry_construction_data(data) {
  return request({
    url: '/api/system/statistic/industry_construction_data',
    method: 'post',
    data,
  })
}
// 项目团队情况
export async function industry_team_data(data) {
  return request({
    url: '/api/system/statistic/industry_team_data ',
    method: 'post',
    data,
  })
}
// 语音
export function audioUpload(data) {
  return request({
    url: 'http://192.168.1.90:5000/upload',
    method: 'post',
    data,
  })
}
// 目标数据
export function index_data(data) {
  return request({
    url: '/api/system/target/index_data',
    method: 'post',
    data,
  })
}
// zheng

export function tget_config(data) {
  return request({
    url: '/api/system/target/get_config',
    method: 'post',
    data,
  })
}
// 跳转运维
export function ex_login(data) {
  return request({
    url: '/api/system/index/ex_login',
    method: 'post',
    data,
  })
}
// 运维登陆token
export function su_login(data) {
  if (process.env.NODE_ENV !== 'development') {
    return request({
      url: 'https://service-operation.shanhainengyuan.com/api/system/index/su_login',
      method: 'post',
      data,
    })
  }else{
    return request({
      url: 'http://hf-d.lx-obj.top/api/system/index/su_login',
      method: 'post',
      data,
    })
  }

}
