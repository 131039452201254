/* unplugin-vue-components disabled */import { useSettingsStore } from '@/store/modules/settings';
export default {
  __name: 'index',
  props: {
    fixedHeader: {
      type: Boolean,

      default() {
        return true;
      }

    },
    showTabs: {
      type: Boolean,

      default() {
        return true;
      }

    }
  },

  setup(__props, {
    expose
  }) {
    expose();
    const settingsStore = useSettingsStore();
    const {
      foldSideBar,
      openSideBar
    } = settingsStore;
    foldSideBar();
    onUnmounted(() => {
      openSideBar();
    });
    const __returned__ = {
      settingsStore,
      foldSideBar,
      openSideBar,
      useSettingsStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }

};